import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import TagManager from "react-gtm-module";

if (process.env.NODE_ENV === "production") {
  const tagManagerArgs = {
    gtmId: "GTM-NN8BLHD",
  };

  TagManager.initialize(tagManagerArgs);
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
