import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import NotFound from "./NotFound";

export default function Work({ match }) {
  const [path, setPath] = useState("");
  const [error, setError] = useState();

  useEffect(() => {
    fetch(process.env.REACT_APP_BACK + "/book/work/" + match.params.id)
      .then((response) => response.json())
      .then((data) => {
        if (data.status && data.status !== 200) {
          setError(data);
        } else if (data) {
          setPath(`/series/${data}`);
        }
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <NotFound data={error} />
      {path ? <Redirect to={path} /> : null}
    </>
  );
}
