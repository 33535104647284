import React, { useState } from "react";

export default function Footer() {
  const [toggle, setToggle] = useState(false);

  return (
    <div id="credit-button" className="pt-2 pb-3">
      <button
        className="gradient-button gradient-underline link-button from-yellow-300 to-yellow-400 font-medium font-semibold 
        outline-none focus:outline-none"
        style={{ textShadow: "#392c41 0 0 10px" }}
        onClick={(e) => {
          e.preventDefault();
          setToggle(true);
        }}
      >
        Credits
      </button>
      {toggle ? (
        <div
          id="credits-modal"
          className="background-opacity fixed inset-0 z-10 flex cursor-pointer items-center justify-center overflow-y-auto"
          onClick={(e) => {
            if (e.target.id === "credits-modal") setToggle(false);
          }}
        >
          <div className="slide-in-left-quick border-gradient relative m-2 cursor-default border-4 bg-white md:max-w-50">
            <ul className="p-5">
              <li>
                Developer: Eudemon (
                <a
                  href="https://github.com/Eudemon"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://github.com/Eudemon
                </a>
                )
              </li>
              <li>Data Source: Goodreads, less-real</li>
              <li>
                Tech stacks: HTML, CSS, JavaScript, React, Node.js, Tailwind,
                Redis, Nginx
              </li>
            </ul>
            <button
              className="absolute top-0 left-full -mt-3 -ml-2 flex h-5 w-5 items-center justify-center rounded-full 
              bg-gradient-to-r from-red-500 to-pink-600 text-white ring hover:from-red-300 hover:to-pink-400"
              onClick={() => setToggle(false)}
            >
              x
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
