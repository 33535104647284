import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import LoadingContext, { Stars, Follow, PlaceHolderImage } from "./Assets";
import NotFound from "./NotFound";

export default function Series({ match }) {
  const { setLoading } = useContext(LoadingContext);
  const [result, setResult] = useState();
  const history = useHistory();
  const [error, setError] = useState();
  const [follow, setFollow] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetch(process.env.REACT_APP_BACK + "/book/series/" + match.params.id)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status && data.status !== 200) {
          setLoading(false);
          setError(data);
        } else {
          console.log(data);
          setResult(data);
          setLoading(false);
        }
      })
      .catch((error) => {
        NotFound(error);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function goBack() {
    if (history.length) {
      history.go(-1);
    }
  }

  return (
    <>
      <NotFound data={error} />
      {!result ? (
        ""
      ) : (
        <div className="">
          <button
            className="absolute bg-white opacity-75 hover:opacity-90"
            onClick={goBack}
          >
            <span className="gradient-button w-full">
              <span className="m-2">
                <i class="fas fa-step-backward"></i>
                Go Back
              </span>
            </span>
          </button>
          <div className="content">
            <img
              src={result.cover}
              alt={(result.title + "").trim()}
              className="float-left mr-2 max-w-50 md:max-w-30"
            ></img>
            <div className="my-2 text-center text-lg font-bold leading-6">
              {result.title}
            </div>
            <button
              className={`max-w-50 rounded bg-gradient-to-r 
              py-1 px-2 text-white hover:from-green-400 
              hover:to-blue-400 focus:from-green-400 focus:to-blue-400 sm:max-w-30 ${
                follow
                  ? "from-green-400 to-blue-400"
                  : "from-purple-400 to-pink-400"
              }`}
              onClick={() => setFollow(!follow)}
            >
              {!follow ? "Follow" : "Coming soon"}
            </button>
            {follow ? (
              <div className="quote p-2 text-green-100">
                <p>
                  <span className="font-medium">Short Story:</span> <br />
                  Beta: Registration and Notification service is not enabled
                  yet.
                </p>
                <p className="pt-1">
                  <span className="font-medium">Long Story:</span> <br />
                  At the end of development, I got the news about the API that I
                  use to pull all book data source is getting deprecated.
                  <br />
                  Currently exploring two methods: <br />
                  <ul className="list-inside list-disc">
                    <li>Find alternative book API source</li>
                    <li>Write my own crawler and book database</li>
                  </ul>
                  The search function will stop working anytime if they decided
                  to revoke my access. <br />
                  Follow me below to get updates:
                  <Follow />
                </p>
              </div>
            ) : null}
            <div className="text-gray-600">Author: {result.author}</div>
            <p>
              <div dangerouslySetInnerHTML={{ __html: result.description }} />
            </p>
            <div class="my-2">
              <div dangerouslySetInnerHTML={{ __html: result.note }} />
            </div>
          </div>
          <div className="clear-both grid grid-cols-2 gap-2 pt-2 lg:grid-cols-3 xl:grid-cols-4">
            {result.series_works[0].series_work.map((val) => {
              const work = val.work[0].best_book[0];
              return (
                <div className="content p-1">
                  {work.image_url[0].includes("nophoto") ? (
                    <PlaceHolderImage classname="small_cover float-left mr-1" />
                  ) : (
                    <img
                      src={work.image_url[0]}
                      alt={work.title}
                      className="float-left mr-1"
                    ></img>
                  )}

                  <div className="">
                    <div>{work.title}</div>
                    <div className="text-gray-500">
                      {`${val.work[0].original_publication_month} / 
                      ${val.work[0].original_publication_day} / 
                      ${val.work[0].original_publication_year}`}
                    </div>
                    <Stars rating={val.work[0].average_rating[0]} shadow={1} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}
