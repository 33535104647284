import React, { useRef, useContext, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import logo_x1 from "../image/eudemon_logo_80.png";
import logo_x2 from "../image/eudemon_logo_160.png";
import logo_x3 from "../image/eudemon_logo_240.png";
import logo_webp_x1 from "../image/eudemon_logo_80.webp";
import logo_webp_x2 from "../image/eudemon_logo_160.webp";
import logo_webp_x3 from "../image/eudemon_logo_240.webp";
import LoadingContext, { Follow } from "./Assets";

export default function Header() {
  const { loading } = useContext(LoadingContext);
  const history = useHistory();
  const term = useRef("");
  const [hover, setHover] = useState(false);

  function handleSearch(e) {
    e.preventDefault();
    history.push("/search/" + encodeURIComponent(term.current.value));
  }

  return (
    <header className="mx-auto flex flex-wrap items-center justify-between py-2">
      <div className="gradient-underline">
        <Link to="/">
          <picture>
            <source
              srcSet={`${logo_webp_x1}, ${logo_webp_x2} 2x, ${logo_webp_x3} 3x`}
              type="image/webp"
            />
            <source
              srcSet={`${logo_x1}, ${logo_x2} 2x, ${logo_x3} 3x`}
              type="image/png"
            />
            <img
              src={logo_x1}
              alt="Logo"
              className="hover-opacity rubber-band rubber-band-animate logo"
            ></img>
          </picture>
        </Link>
      </div>
      <form
        className="mx-auto my-3 flex items-center rounded-full bg-white shadow-xl hover:ring-2 focus:ring-2"
        onSubmit={handleSearch}
      >
        <input
          id="search"
          name="search"
          type="text"
          placeholder="Find a book"
          className="w-full rounded-l-full py-4 px-6 leading-tight text-gray-700 focus:outline-none"
          ref={term}
          aria-label="Search a book"
        />
        <div className="p-2">
          <button
            className={`rubber-band-trigger flex h-12 w-12 items-center justify-center rounded-full bg-blue-500 p-2 text-white hover:bg-blue-400 
            focus:outline-none ${loading ? "mr-1 bg-green-400" : ""}`}
            type="submit"
            onMouseOver={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            aria-label="Search Button"
          >
            <i
              className={`fas rubber-band fa-lg ${
                hover ? "rubber-band-trigger" : ""
              } ${
                loading ? "fa-circle-notch fa-spin fa-spinner" : "fa-search"
              }`}
            ></i>
          </button>
        </div>
      </form>
      <span className="">
        <Follow />
      </span>
    </header>
  );
}
