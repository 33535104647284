import React, { useState } from "react";
import "../css/main.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Search from "./Search";
import Work from "./Work";
import Series from "./Series";
import Book from "./Book";
import Header from "./Header";
import Home from "./Home";
import Footer from "./Footer";
import LoadingContext, { SkeletonLoader } from "./Assets";
import pagetop from "../image/pagetop.png";
import pageTopWebp from "../image/pagetop.webp";
import yurucamp from "../image/yurucamp.gif";

function App() {
  const [loading, setLoading] = useState(false);
  const [scroll, setScroll] = useState(false);

  const checkScrollTop = () => {
    if (!scroll && window.pageYOffset > 300) {
      setScroll(true);
    } else if (scroll && window.pageYOffset <= 300) {
      setScroll(false);
    }
  };

  function scrollTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  window.addEventListener("scroll", checkScrollTop);

  return (
    <LoadingContext.Provider value={{ loading, setLoading }}>
      <div>
        <svg
          class="waves"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 28"
          preserveAspectRatio="none"
          shape-rendering="auto"
        >
          <defs>
            <path
              id="gentle-wave"
              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
            />
          </defs>
          <g class="parallax">
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="0"
              fill="rgba(255,255,255,0.6)"
            />
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="3"
              fill="rgba(255,255,255,0.4)"
            />
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="5"
              fill="rgba(255,255,255,0.2)"
            />
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="7"
              fill="rgba(255,255,255,0.8)"
            />
          </g>
        </svg>
      </div>
      <Router>
        <div className="container relative mx-auto lg:max-w-90 lg:px-6">
          <Header />
          <main className="content-body rounded-lg p-2 lg:p-3">
            {loading ? <SkeletonLoader /> : ""}
            <Switch>
              <Route path="/search/:term" component={Search} />
              <Route path="/work/:id" component={Work} />
              <Route path="/series/:id" component={Series} />
              <Route path="/book/:id" component={Book} />
              <Route path="/" component={Home} />
            </Switch>
            <img
              src={yurucamp}
              className="absolute"
              style={{ zIndex: "-1", left: "-10%", bottom: "-5%" }}
              alt="camp fire"
            />
          </main>
          <Footer />
          <ul className="circles">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>

          <button
            className="scroll-top z-1 fixed right-0 outline-none focus:outline-none"
            style={{
              bottom: scroll ? "-3px" : "-150px",
            }}
            onClick={scrollTop}
            aria-label="Scroll Top"
          >
            <picture>
              <source srcSet={pageTopWebp} type="image/webp" />
              <source srcSet={pagetop} type="image/png" />
              <img src={pagetop} alt="Scroll Top Icon" />
            </picture>
          </button>
        </div>
      </Router>
    </LoadingContext.Provider>
  );
}

export default App;
