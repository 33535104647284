import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { TagCloud } from 'react-tagcloud';

export default function Home() {
  const history = useHistory();
  const [quote, setQuote] = useState();
  const [recent, setRecent] = useState();
  useEffect(() => {
    getQuote();
    getRecent();
  }, []);

  function getQuote() {
    fetch(process.env.REACT_APP_BACK + '/home/quote')
      .then((response) => response.json())
      .then((data) => {
        setQuote(data);
        // debugger
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getRecent() {
    fetch(process.env.REACT_APP_BACK + '/home/recent')
      .then((response) => response.json())
      .then((data) => {
        let words = [];
        for (let val in data) {
          words.push({ value: val, count: data[val] });
        }
        setRecent(words);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function hexToHSL(H, brightness = 100) {
    // Convert hex to RGB first
    let r = 0,
      g = 0,
      b = 0;
    if (H.length === 4) {
      r = '0x' + H[1] + H[1];
      g = '0x' + H[2] + H[2];
      b = '0x' + H[3] + H[3];
    } else if (H.length === 7) {
      r = '0x' + H[1] + H[2];
      g = '0x' + H[3] + H[4];
      b = '0x' + H[5] + H[6];
    }
    // Then to HSL
    r /= 255;
    g /= 255;
    b /= 255;
    let cmin = Math.min(r, g, b),
      cmax = Math.max(r, g, b),
      delta = cmax - cmin,
      h = 0,
      s = 0,
      l = 0;

    if (delta === 0) h = 0;
    else if (cmax === r) h = ((g - b) / delta) % 6;
    else if (cmax === g) h = (b - r) / delta + 2;
    else h = (r - g) / delta + 4;

    h = Math.round(h * 60);

    if (h < 0) h += 360;

    l = (cmax + cmin) / 2;
    s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    s = +(s * 100).toFixed(1);
    l = +(l * brightness).toFixed(1);

    return 'hsl(' + h + ',' + s + '%,' + l + '%)';
  }

  const customRenderer = (tag, size, color) => (
    <Link
      style={{
        margin: '0.37rem',
      }}
      key={tag.value}
      to={`/search/${encodeURIComponent(tag.value)}`}
    >
      <span
        key={tag.value}
        style={{
          fontSize: `${size / 10}rem`,
          display: 'inline',
          color: `${hexToHSL(color, 60)}`,
          textShadow: `0 0 2px gray`,
        }}
      >
        {tag.value}
        <span
          style={{
            fontSize: `${size / 20}rem`,
            verticalAlign: 'top',
            display: 'inline-block',
            color: `${hexToHSL(color, 75)}`,
          }}
        >
          {tag.count}
        </span>
      </span>
    </Link>
  );

  return (
    <div className="md:max-w-75 mx-auto content-center antialiased">
      <div className="mb-5 text-lg font-medium leading-6">
        <span className="text-gradient from-red-500 to-purple-500 text-2xl font-semibold">
          What is this? (Beta)
        </span>
        <p className="m-1">
          A Free Service that will{' '}
          <span className="text-gradient from-green-600 to-green-900 text-lg font-semibold">
            notify
          </span>{' '}
          you on{' '}
          <span className="text-gradient from-red-500 via-red-500 to-yellow-600 text-lg font-semibold">
            new releases
          </span>{' '}
          of any book series in the world in{' '}
          <span className="text-gradient from-blue-500 to-purple-600 text-lg font-semibold">
            any language
          </span>
          .
        </p>
        <p className="text-base">
          I started the project because I read lot of manga and hard to keep up
          with latest volumes. Figured to share with the world in case anyone
          find any use.
        </p>
        <div className="py-5 text-center">
          <span className="text-xl font-semibold text-gray-700">
            <i class="fas fa-caret-down text-blue-500"></i> Recent Searches{' '}
            <i class="fas fa-caret-down text-green-600"></i>
          </span>
          {recent ? (
            <TagCloud
              minSize={10}
              maxSize={20}
              tags={recent}
              className="tag-cloud mt-1"
              renderer={customRenderer}
            />
          ) : null}
        </div>
      </div>
      {!quote ? (
        ''
      ) : (
        <>
          <div className="slide-in-left sticky z-10 px-8">
            <blockquote>
              <div className="quote mb-2 p-1">
                <div dangerouslySetInnerHTML={{ __html: quote.quote }} />
              </div>
              {/* <div className="text-right">
                <span className="text-white quote py-1 px-2">
                  {quote.character} -{" "}
                  <Link to={`/search/${encodeURI(quote.anime)}`}>
                    {quote.anime}
                  </Link>
                </span>
              </div> */}
              <div></div>
            </blockquote>
          </div>

          <div className="max-w-95 -mt-12 pl-8">
            <img
              src={quote.image}
              alt={quote.title}
              // onClick={handleImage}
              // onMouseOver={() => setHover(true)}
              // className="hover-opacity border-4 border-gradient cursor-pointer"
            ></img>
          </div>
        </>
      )}
    </div>
  );
}
