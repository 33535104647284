import React from "react";
import hani from "../image/hani.jpg";

export default function NotFound({ data }) {
  return (
    <>
      {data ? (
        <center>
          <span className="text-gradient from-red-400 via-red-500 to-yellow-600 text-4xl font-medium">
            {data.status}
          </span>
          <div className="m-2">
            <span className="text-gradient from-blue-600 to-purple-600 text-lg font-medium">
              {data.message}
            </span>
          </div>
          <img src={hani} alt="not found hani"></img>
          Oh no!
        </center>
      ) : (
        ""
      )}
    </>
  );
}
