import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Stars, PlaceHolderImage } from "./Assets";

export default function BookCard({ id, book, rating, count }) {
  const history = useHistory();
  const [hover, setHover] = useState(false);
  function handleClick() {
    history.push("/work/" + id);
  }

  return (
    <div
      className="book-card max-w-xs cursor-pointer overflow-hidden overflow-visible rounded 
        bg-white shadow-lg ring-yellow-200 hover:ring"
      onClick={handleClick}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className="relative overflow-hidden rounded-t">
        <span
          className={`tracking-in-expand absolute top-0 left-1 z-10 ${
            hover ? "inline-block" : "hidden"
          }`}
        >
          <Stars rating={rating} count={count} />
        </span>
        <span
          className={`quote tracking-in-expand absolute bottom-0 right-0 z-10 px-1 text-sm ${
            hover ? "inline-block" : "hidden"
          }`}
        >
          {book.author[0].name[0]}
        </span>
        {book.image_url[0].includes("nophoto") ? (
          <PlaceHolderImage classname="w-full" />
        ) : (
          <img
            src={book.image_url[0].replace(new RegExp("._.+_"), "")}
            className="w-full"
            alt={book.title[0]}
          />
        )}
      </div>
      <div className="px-3 pb-2">
        <p className="text-base font-semibold">{book.title[0]}</p>
      </div>
    </div>
  );
}
