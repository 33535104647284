import React, { useEffect, useState } from "react";
import BookCard from "./BookCard";
import Masonry from "react-masonry-css";
import NotFound from "./NotFound";
import { breakpointColumnsObj } from "./Assets";

export default function Book({ match }) {
  const [error, setError] = useState();
  const [result, setResult] = useState();
  useEffect(() => {
    fetch(process.env.REACT_APP_BACK + "/book/book/" + match.params.id)
      .then((response) => response.json())
      .then((data) => {
        if (data.status && data.status !== 200) {
          setError(data);
        } else {
          setResult(data);
        }
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <NotFound data={error} />
      {result ? (
        <div className="flex flex-wrap">
          <div className="">
            <div className="my-2 text-center text-lg font-bold leading-6">
              {result.title}
            </div>
            <img
              src={result.image_url[0].replace(new RegExp("._.+_"), "")}
              alt={(result.title + "").trim()}
              className="max-w-70 float-left mr-2"
            ></img>

            <div className="flex flex-wrap gap-x-6 text-gray-600">
              <span>Author: {result.authors[0].author[0].name}</span>
              <span>
                Issue Date: {result.publication_month} /{" "}
                {result.publication_day} / {result.publication_year}
              </span>
              <span>Rating: {result.average_rating}</span>
              <span>Pages: {result.num_pages}</span>
              <span>Publisher: {result.publisher}</span>
            </div>
            <p>
              <div dangerouslySetInnerHTML={{ __html: result.description }} />
            </p>
            <div class="my-2">
              <div dangerouslySetInnerHTML={{ __html: result.note }} />
            </div>
          </div>

          <div className="">
            <span className="pl-1 font-semibold">Related books:</span>
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="m-auto flex"
              columnClassName="masonry-grid_column_sm"
            >
              {result.similar_books[0].book.map((val) => {
                val.author = val.authors[0].author;
                return (
                  <BookCard key={val.id} id={val.work[0].id[0]} book={val} />
                );
              })}
            </Masonry>
          </div>
        </div>
      ) : null}
    </>
  );
}
