import React, { useState, useEffect, useContext } from "react";
import BookCard from "./BookCard";
import LoadingContext, { breakpointColumnsObj } from "./Assets";
import Masonry from "react-masonry-css";
import InfiniteScroll from "react-infinite-scroll-component";
import { SkeletonLoader } from "./Assets";

export default function Search({ match }) {
  const { setLoading } = useContext(LoadingContext);
  const [result, setResult] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    // debugger
    if (result.length) {
      setResult([]);
      if (page === 1) handleSearch();
    }
    setHasMore(true);
    setPage(1);
  }, [match.params.term]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(handleSearch, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  function handleSearch() {
    setLoading(true); // eslint-disable-line react-hooks/exhaustive-deps

    fetch(
      process.env.REACT_APP_BACK +
        "/book/search/" +
        match.params.term +
        "/" +
        page
    )
      .then((response) => response.json())
      .then((data) => {
        const hasResult = data[0]["total-results"][0] === "0" ? false : true;
        if (!hasResult || data[0].results[0].work.length < 20) {
          setHasMore(false);
        }

        if (hasResult) {
          setResult((prev) => {
            return [...prev, ...data[0].results[0].work];
          });
        }

        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <InfiniteScroll
      dataLength={result.length}
      next={() => setPage(page + 1)}
      hasMore={result.length ? hasMore : false}
      loader={<SkeletonLoader />}
      className="-m-1"
    >
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="m-auto flex"
        columnClassName="masonry-grid_column"
      >
        {result
          ? result.map((val) => {
              val.average_rating = Math.round(parseInt(val.average_rating));
              val.best_book[0].image_url[0] =
                val.best_book[0].image_url[0].replace(new RegExp("._.+_"), "");
              return (
                <BookCard
                  key={val.id[0]._}
                  id={val.id[0]._}
                  book={val.best_book[0]}
                  rating={val.average_rating}
                  count={val.ratings_count[0]._}
                />
              );
            })
          : ""}
      </Masonry>
    </InfiniteScroll>
  );
}
